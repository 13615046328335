<template>
  <div class="page-container">
    <progress-step :step="page.step"></progress-step>
    <div class="page-content">
      <v-card flat>
        <v-list class="transaction" subheader two-line flat>
          <v-list-item-group class="df-b-c" v-model="settings">
            <v-card-text class="bold-f-s">
              <div>{{transactionMode.question}}</div>
            </v-card-text>
            <v-list-item
              v-for="(item, index) in transactionMode.slides"
              :key="index"
              @click="chooseTransactionMode(item)"
            >
              <template v-slot:default="{ active }">
                <v-list-item-action class="m-r-5">
                  <v-checkbox
                    :input-value="active"
                    color="primary"
                  ></v-checkbox>
                </v-list-item-action>
                <v-list-item-content style="padding-bottom: 0px;">
                  <v-card :class="active == 1 ? 'df-b-c' : ''" style="border-radius: 15px" flat
                          v-bind:id="item.title">
                    <v-card-text class="bold-f-s p-b-0">{{item.title}}</v-card-text>
                    <v-card-text class="p-t-5" style="padding-bottom: 0px">{{item.note}}</v-card-text>
                  </v-card>
                </v-list-item-content>
              </template>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-card>
    </div>
    <div class="page-action" v-if="showFooter">
      <v-card flat>
        <v-card-text>
          <i style="font-size: 14px">* Unavoidable delays that occur due to bank holidays, processing schedule of your
            bank, processing errors, “acts of God,” and/or “acts of terror” may extend the time for the deposit</i>
        </v-card-text>
      </v-card>
    </div>
    <v-overlay
      absolute
      :value="overlay"
    >
      <v-icon class="mdi mdi-spin mdi-loading" large></v-icon>
    </v-overlay>
    <v-dialog persistent max-width="600px" v-model="showModal">
      <v-card flat>
        <v-card-text class="p-t-20 bold-f-s text-center">Please confirm your choices</v-card-text>
        <v-card-text class="p-b-10">
          <v-row>
            <v-col cols="6">
              <label>Repayment Type:</label>
            </v-col>
            <v-col class="text-right" cols="6">
              <span :style="`color: ${$vuetify.theme.themes.light.primary} !important`"
                    style="font-weight: 600">
                {{transactionMode.repayment}}
              </span>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider class="m-r-20 m-l-20"></v-divider>
        <v-card-text class="p-t-10">
          <v-row>
            <v-col cols="6">
              <label>Funding Type:</label>
            </v-col>
            <v-col class="text-right" cols="6">
            <span :style="`color: ${$vuetify.theme.themes.light.primary} !important`" style="font-weight: 600">
              {{transactionMode.disbursement}}
            </span>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="p-b-20">
          <v-btn
            large
            block
            outlined
            class="width-40 m-l-8"
            @click="cancel"
          >
            NO
          </v-btn>
          <v-btn
            large
            block
            color="primary"
            class="width-40"
            @click="confirm"
          >
            YES
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog persistent max-width="600px" v-model="showModalACH">
      <v-card flat>
        <v-card-text class="f-s-24 bold-f p-t-24 text-center">Optional</v-card-text>
        <v-card-text><span class="bold-f">Adding a Debit Card</span> as an alternative payment method to avoid potential
          ACH fees?
        </v-card-text>
        <v-card-actions class="p-b-20">
          <v-btn
            large
            block
            outlined
            class="width-50"
            @click="ACHNo"
          >
            NO
          </v-btn>
          <v-btn
            large
            block
            color="primary"
            class="width-50 m-r-0"
            @click="ACHYes"
          >
            YES
          </v-btn>
        </v-card-actions>
      </v-card>
      <v-card
        style="background: transparent;"
        flat
        class="text-center m-t-15"
      >
        <v-btn
          @click="settings=undefined, showModalACH=false" icon>
          <v-icon color="white">close</v-icon>
        </v-btn>
      </v-card>
    </v-dialog>
    <v-dialog persistent max-width="600px" v-model="dialogMail">
      <v-card flat>
        <v-card-text class="f-s-24 bold-f p-t-24 text-center">Optional</v-card-text>
        <v-card-text>
          If you choose physical repayment, you <span class="bold-f">cannot receive your loan proceeds
          electronically</span>. If approved, your loan
          proceeds will be mailed to you in <span class="bold-f">5 days</span>. Other limitations apply.
        </v-card-text>
        <v-card-actions class="p-b-20">
          <v-btn
            large
            block
            outlined
            class="width-50"
            @click="settings=undefined, dialogMail=false"
          >
            NO
          </v-btn>
          <v-btn
            large
            block
            color="primary"
            class="width-50 m-r-0"
            @click="dialogMailY"
          >
            YES
          </v-btn>
        </v-card-actions>
      </v-card>
      <v-card
        style="background: transparent;"
        flat
        class="text-center m-t-15"
      >
        <v-btn
          @click="settings=undefined, dialogMail=false" icon>
          <v-icon color="white">close</v-icon>
        </v-btn>
      </v-card>
    </v-dialog>
    <v-dialog persistent max-width="600px" v-model="showModalEmail">
      <v-card flat>
        <v-card-text class="p-t-20">We need more information based on your choice of repayment, please contact us
          through {{ portfolio.telephone }}
        </v-card-text>
        <follow-up @submit="followUp" @close="followUpClose"></follow-up>
      </v-card>
    </v-dialog>
    <v-dialog persistent max-width="600px" v-model="showModalInstantCard">
      <instant-card :portfolio="portfolio" @submit="submit"></instant-card>
      <v-card
        style="background: transparent;"
        flat
        class="text-center m-t-15"
      >
        <v-btn @click="showModalInstantCard=false" icon>
          <v-icon color="white">close</v-icon>
        </v-btn>
      </v-card>
    </v-dialog>
    <v-dialog persistent max-width="600px" v-model="showRepaymentMail">
      <v-card flat>
        <v-card-text class="p-t-20 bold-f-s text-center">Please confirm your choices</v-card-text>
        <v-card-text>
          If approved,your loan proceeds will be mailed to you in <span class="bold-f">5 days</span>. Other limitations
          apply.
        </v-card-text>
        <v-card-actions class="p-b-20">
          <v-btn
            large
            block
            outlined
            class="width-40 m-l-8"
            @click="settings=undefined, showRepaymentMail=false"
          >
            NO
          </v-btn>
          <v-btn
            large
            block
            color="primary"
            class="width-40"
            @click="showRepaymentMail=false, showModalEmail = true"
          >
            YES
          </v-btn>
        </v-card-actions>
      </v-card>
      <v-card
        style="background: transparent;"
        flat
        class="text-center m-t-15"
      >
        <v-btn @click="showRepaymentMail=false" icon>
          <v-icon color="white">close</v-icon>
        </v-btn>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

import { OriginationApi } from '../../api/application'
import { DataType, Tool } from '../../js/core'
import InstantCard from '../../components/ailurus/InstantCardModal'
import ProgressStep from '../../components/progress/ProgressStep'
import FollowUp from '../../components/ailurus/dialog/FollowUp'

const tool = new Tool()

export default {
  components: { FollowUp, ProgressStep, InstantCard },
  data () {
    return {
      page: DataType.PAGES.TRANSACTION,
      overlay: false,
      showFooter: false,
      showModalEmail: false,
      portfolio: {},
      settings: undefined,
      showModal: false,
      showModalInstantCard: false,
      showModalACH: false,
      showRepaymentMail: false,
      dialogMail: false,
      question: {
        repayment: 'Choose your repayment option:',
        disbursement: 'Choose your funding method'
      },
      transactionMode: {
        slides: [],
        question: '',
        repayment: '',
        disbursement: ''
      },
      repayment: [
        {
          mode: '1',
          title: 'Electronic',
          note: 'Payment will be charged through ACH or Debit Card.',
          style: ''
        },
        {
          mode: '2',
          title: 'Check by Mail',
          note: 'Payment will be sent through check by mail.',
          style: ''
        }
      ],
      disbursement: [
        // {
        //   mode: '3',
        //   title: 'Debit Instant Fund',
        //   note: 'Funds will be deposited within 1 hour of approval.',
        //   style: ''
        // },
        {
          mode: '4',
          title: 'Electronic',
          note: 'Funds will be deposited within 24 hours of approval. *',
          style: ''
        },
        {
          mode: '5',
          title: 'Check by Mail',
          note: 'Payment will be sent through check by mail.',
          style: ''
        }
      ],
      email: [
        {
          mode: '5',
          title: 'Paper check',
          note: 'NOTE: A paper check will be delivered to you in 5 business days',
          style: ''
          // color: 'grey'
        }
      ],
      progress: 7,
      step: 'bank',
      notes: {
        noteContent: ''
      }
    }
  },
  methods: {
    async followUp (data) {
      this.overlay = true
      this.showModalEmail = false
      if (tool.isNotEmpty(data.followUpAt)) {
        await OriginationApi.updateOrigination({
          feature: 'follow-up',
          data: {
            loanId: data.loanId,
            followUpAt: data.followUpAt,
            notes: this.notes
          }
        })
        if (data.cellPhone) {
          data.cellPhone = data.cellPhone.replace(/[^0-9]/ig, '')
        }
        if (data.homePhone) {
          data.homePhone = data.homePhone.replace(/[^0-9]/ig, '')
        }
        await OriginationApi.updateOrigination({
          feature: 'personal',
          data: {
            loanId: data.loanId,
            cellPhone: data.cellPhone,
            homePhone: data.homePhone
          }
        })
      }
      await OriginationApi.updateOrigination({
        feature: 'loan-status',
        data: {
          loanId: data.loanId,
          loanStatus: DataType.LoanStatus.AGENT_REVIEW.value
        }
      })
      this.$router.push('/notification/-100')
    },
    followUpClose () {
      this.showModalEmail = false
      this.settings = undefined
    },
    ACHYes () {
      this.showModalInstantCard = true
      this.settings = undefined
      this.showModalACH = false
    },
    ACHNo () {
      this.transactionMode.question = this.question.disbursement
      this.transactionMode.slides = this.disbursement
      this.showModalACH = false
      this.settings = false
      this.showFooter = true
    },
    submit (data) {
      this.showModalInstantCard = false
      this.settings = undefined
      this.transactionMode.question = this.question.disbursement
      this.transactionMode.slides = this.disbursement
      this.showFooter = true
    },
    cancel () {
      this.transactionMode.question = this.question.repayment
      this.transactionMode.slides = this.repayment
      this.settings = undefined
      this.showModal = false
      this.showFooter = false
    },
    confirm () {
      this.showModal = false
      const updatePaymentOption = {}
      updatePaymentOption.loanId = localStorage.getItem(DataType.COOKIE_KEY.LOAN_ID)
      if (this.transactionMode.repayment === 'Check by Mail') {
        updatePaymentOption.defaultRepaymentMode = 0
      } else {
        updatePaymentOption.defaultRepaymentMode = 15000
      }
      if (this.transactionMode.disbursement === 'Check by Mail') {
        updatePaymentOption.defaultDisbursementMode = 0
      } else {
        updatePaymentOption.defaultDisbursementMode = 15000
      }
      OriginationApi.updateOrigination({ feature: 'payment-option', data: updatePaymentOption }, function (result) {
      })
      const stepMessage = {
        currect: DataType.PAGES.TRANSACTION,
        next: DataType.PAGES.MILITARY
      }
      this.$eventBus.$emit('sendStepToSloth', stepMessage)
      OriginationApi.enteredAndLeft(
        localStorage.getItem(DataType.COOKIE_KEY.LOAN_ID),
        DataType.PAGES.TRANSACTION.step,
        DataType.PAGES[this.page.next].step,
        localStorage.getItem(DataType.COOKIE_KEY.PORTFOLIO_ID)
      )
      this.$router.push(DataType.PAGES[this.page.next].address)
    },
    dialogMailY () {
      this.showModalEmail = true
      this.notes.noteContent = 'Repayment: Check by Mail'
    },
    chooseTransactionMode (item) {
      const _this = this
      switch (item.mode) {
        case '1': {
          setTimeout(function () {
            if (_this.settings !== undefined) {
              _this.transactionMode.repayment = item.title
              _this.showModalACH = true
            }
          }, 100)
          break
        }
        case '3': {
          setTimeout(function () {
            if (_this.settings !== undefined) {
              _this.showModalInstantCard = true
              _this.transactionMode.disbursement = item.title
              _this.settings = undefined
            }
          }, 100)
          break
        }
        case '2': {
          setTimeout(function () {
            if (_this.settings !== undefined) {
              _this.dialogMail = true
              _this.transactionMode.repayment = item.title
              _this.transactionMode.disbursement = item.title
            }
          }, 100)
          break
        }
        case '4': {
          setTimeout(function () {
            _this.transactionMode.disbursement = item.title
            _this.showModal = true
          }, 100)
          break
        }
        case '5': {
          setTimeout(function () {
            if (_this.settings !== undefined) {
              _this.transactionMode.disbursement = item.title
              _this.showRepaymentMail = true
              _this.notes.noteContent = 'Funding: Check by Mail'
            }
          }, 100)
          break
        }
        default : {
          if (_this.settings === undefined) {
            _this.transactionMode.disbursement = item.title
            _this.showModal = true
            break
          }
        }
      }
    }
  },
  created () {
    this.$store.commit('setCurrentPage', this.page)
    this.transactionMode.question = this.question.repayment
    this.transactionMode.slides = this.repayment
    this.portfolio = JSON.parse(localStorage.getItem(DataType.COOKIE_KEY.PORTFOLIO))
    // 跳转页面
    const _this = this
    OriginationApi.pageControl(null, function (pageControl) {
      if (
        pageControl !== null &&
        pageControl !== undefined &&
        pageControl.indexOf(localStorage.getItem(DataType.COOKIE_KEY.PORTFOLIO_ID)) > 0
      ) {
        _this.page.next = 'MILITARY'
      }
    })
  }
}
</script>
<style lang="css">
  .transaction .v-input--selection-controls__input {
    position: absolute;
    right: -38px;
    z-index: 30;
  }

  .transaction .mdi-checkbox-blank-outline::before {
    content: "\F130";
    font-size: 18px;
  }

  .transaction .mdi-checkbox-marked::before {
    content: "\F133";
    font-size: 18px;
  }
</style>
